import React from 'react'
import MonthlyData from './MonthlyData'
import { PostUsageReportPefindoMonthlyData } from '../../utils/api'

const App = () => {
    const postDataUrlFn = PostUsageReportPefindoMonthlyData
    const pushEventMessage = 'Usage Report Slik Download Click'
    const responseDataTransformer = x => ({
        'Nama Klien': x.company_name,
        'Tanggal Proses': x.processed_at,
        'Nama Debitur': x.borrower_name,
        'Tanggal Hapus': x.deleted_at,
        'Diproses Oleh': x.processed_by
    })
    const excelColsConfigs = [{ wch: 25 }, { wch: 18 }, { wch: 45 }, { wch: 18 }, { wch: 18 }]
    return (
        <MonthlyData postDataUrlFn={postDataUrlFn}
            pushEventMessage={pushEventMessage}
            responseDataTransformer={responseDataTransformer}
            excelColsConfigs={excelColsConfigs}
            prefixName="pfdmd" />
    )
}

export default App
