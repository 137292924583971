import Axios from 'axios'
import { getCookie } from './cookie'

// Version 1.0.0.2
Axios.interceptors.request.use(
    config => {
        if (!config.headers.Authorization) {
            const token = getCookie()
            if (token) {
                config.headers.Authorization = token
            }
        }

        return config
    },
    error => Promise.reject(error)
)

// Auth user.
export const PostAuthUserPreLogin = () => `${process.env.REACT_APP_AUTH_API_URL}/user/pre-login`
export const PostAuthUserLogin = () => `${process.env.REACT_APP_AUTH_API_URL}/user/login`
export const PostAuthUserRegister = () => `${process.env.REACT_APP_AUTH_API_URL}/user/register`
export const PostAuthUserVerify = () => `${process.env.REACT_APP_AUTH_API_URL}/user/verify`
export const PostAuthUserLogout = () => `${process.env.REACT_APP_AUTH_API_URL}/user/logout`
export const GetAuthUserMenu = () => `${process.env.REACT_APP_AUTH_API_URL}/user/menu`

// Profile user
export const GetUserDetail = () => `${process.env.REACT_APP_AUTH_API_URL}/user/detail`
export const PutUserChangePassword = () => `${process.env.REACT_APP_AUTH_API_URL}/user/change-password`
export const GetUserQRCode2FA = () => `${process.env.REACT_APP_AUTH_API_URL}/user/detail/qrcode/${process.env.REACT_APP_DASHBOARD_NAME || 'Fineksi'}`
export const PutUser2FASetting = () => `${process.env.REACT_APP_AUTH_API_URL}/user/detail/two-factor-auth`

// Statement.
export const GetStatementList = () => `${process.env.REACT_APP_APP_API_URL}/statement/list`
export const PostStatementUpload = (mergeUuid) => `${process.env.REACT_APP_APP_API_URL}/statement/upload/${mergeUuid}`
export const PostStatementPreprocess = (uuid) => `${process.env.REACT_APP_APP_API_URL}/statement/preprocess/${uuid}`
export const PostStatementProcess = (uuid) => `${process.env.REACT_APP_APP_API_URL}/statement/process/${uuid}`
export const DeleteStatement = (uuid) => `${process.env.REACT_APP_APP_API_URL}/statement/delete/${uuid}`

// Merge Statement.
export const GetMergeStatementList = () => `${process.env.REACT_APP_APP_API_URL}/merge-statement/list`
export const GetMergeStatementDetail = (uuid) => `${process.env.REACT_APP_APP_API_URL}/merge-statement/detail/${uuid}`
export const PostMergeStatementMerge = () => `${process.env.REACT_APP_APP_API_URL}/merge-statement/merge`
export const DeleteMergeStatement = (uuid) => `${process.env.REACT_APP_APP_API_URL}/merge-statement/delete/${uuid}`
export const PostMergeStatementUpload = () => `${process.env.REACT_APP_APP_API_URL}/merge-statement/upload`
export const PutMergeStatementDashboardStatus = (uuid) => `${process.env.REACT_APP_APP_API_URL}/merge-statement/dashboard-status/${uuid}`

// Slik.
export const GetSlikList = () => `${process.env.REACT_APP_APP_API_URL}/slik/list`
export const PostSlikUpload = (mergeUuid) => `${process.env.REACT_APP_APP_API_URL}/slik/upload/${mergeUuid}`
export const PostSlikProcess = (uuid) => `${process.env.REACT_APP_APP_API_URL}/slik/process/${uuid}`
export const DeleteSlik = (uuid) => `${process.env.REACT_APP_APP_API_URL}/slik/delete/${uuid}`

// Merge Slik.
export const GetMergeSlikList = () => `${process.env.REACT_APP_APP_API_URL}/merge-slik/list`
export const GetMergeSlikDetail = (uuid) => `${process.env.REACT_APP_APP_API_URL}/merge-slik/detail/${uuid}`
export const PostMergeSlikMerge = () => `${process.env.REACT_APP_APP_API_URL}/merge-slik/merge`
export const DeleteMergeSlik = (uuid) => `${process.env.REACT_APP_APP_API_URL}/merge-slik/delete/${uuid}`
export const PostMergeSlikUpload = () => `${process.env.REACT_APP_APP_API_URL}/merge-slik/upload`

// Slik.
export const GetPefindoList = () => `${process.env.REACT_APP_APP_API_URL}/pefindo/list`
export const PostPefindoUpload = (mergeUuid) => `${process.env.REACT_APP_APP_API_URL}/pefindo/upload/${mergeUuid}`
export const PostPefindoProcess = (uuid) => `${process.env.REACT_APP_APP_API_URL}/pefindo/process/${uuid}`
export const DeletePefindo = (uuid) => `${process.env.REACT_APP_APP_API_URL}/pefindo/delete/${uuid}`

// Merge Pefindo.
export const GetMergePefindoList = () => `${process.env.REACT_APP_APP_API_URL}/merge-pefindo/list`
export const GetMergePefindoDetail = (uuid) => `${process.env.REACT_APP_APP_API_URL}/merge-pefindo/detail/${uuid}`
export const PostMergePefindoMerge = () => `${process.env.REACT_APP_APP_API_URL}/merge-pefindo/merge`
export const DeleteMergePefindo = (uuid) => `${process.env.REACT_APP_APP_API_URL}/merge-pefindo/delete/${uuid}`
export const PostMergePefindoUpload = () => `${process.env.REACT_APP_APP_API_URL}/merge-pefindo/upload`

// Applicant Verification.
export const GetApplicantList = () => `${process.env.REACT_APP_CORE_API_URL}/applicant/list`
export const GetApplicantDetail = (uuid) => `${process.env.REACT_APP_CORE_API_URL}/applicant/detail/${uuid}`
export const PostCreateApplicant = () => `${process.env.REACT_APP_CORE_API_URL}/applicant/create`
export const GetApplicantFile = (uuid, key) => `${process.env.REACT_APP_CORE_API_URL}/applicant/file/${uuid}/${key}`
export const DeleteApplicant = (uuid) => `${process.env.REACT_APP_CORE_API_URL}/applicant/delete/${uuid}`
export const GetApplicantIncomeDetail = (uuid) => `${process.env.REACT_APP_CORE_API_URL}/applicant/detail-income/${uuid}`
export const PostApplicantIncomeVerification = (uuid) => `${process.env.REACT_APP_CORE_API_URL}/applicant/verify-income/${uuid}`
export const GetApplicantKycDetail = (uuid) => `${process.env.REACT_APP_CORE_API_URL}/applicant/detail-kyc/${uuid}`
export const PostApplicantKycVerification = (uuid) => `${process.env.REACT_APP_CORE_API_URL}/applicant/verify-kyc/${uuid}`

// Usage Report.
export const GetUsageReportSection = () => `${process.env.REACT_APP_CORE_API_URL}/usage-report/section`
export const PostUsageReportStatementChart = () => `${process.env.REACT_APP_CORE_API_URL}/usage-report/statement-chart`
export const PostUsageReportSlikChart = () => `${process.env.REACT_APP_CORE_API_URL}/usage-report/slik-chart`
export const PostUsageReportPefindoChart = () => `${process.env.REACT_APP_CORE_API_URL}/usage-report/pefindo-chart`
export const PostUsageReportVerificationIncomeChart = () => `${process.env.REACT_APP_CORE_API_URL}/usage-report/verification-income-chart`
export const PostUsageReportStatementMonthlyData = () => `${process.env.REACT_APP_CORE_API_URL}/usage-report/statement-monthly-data`
export const PostUsageReportSlikMonthlyData = () => `${process.env.REACT_APP_CORE_API_URL}/usage-report/slik-monthly-data`
export const PostUsageReportPefindoMonthlyData = () => `${process.env.REACT_APP_CORE_API_URL}/usage-report/pefindo-monthly-data`

// Autocomplete.
export const GetAutocompletePartnerList = () => `${process.env.REACT_APP_CORE_API_URL}/autocomplete/partner/list`

// Trail.
export const PostTrailMonthlyData = () => `${process.env.REACT_APP_CORE_API_URL}/trail/monthly-data`

// Upload
export const PostDocUpload = () => `${process.env.REACT_APP_CORE_API_URL}/doc/upload`

// Get IP Address.
export const RetrieveClientIpAddress = () => `${process.env.REACT_APP_IPADDRESS_URL}`
