/* eslint-disable no-unused-vars */
import Axios from 'axios'
import React, { useContext, useState, useEffect } from 'react'
import { Row, Col, Spinner, Card, Button } from 'react-bootstrap'
import { useParams, useNavigate, Link } from 'react-router-dom'

import Breadcrumbs from '../../Shared/Breadcrumbs'

import NotificationContext from '../../../contexts/notification'
import { GetApplicantDetail } from '../../../utils/api'
import { dateParserWithFormat } from '../../../utils/time'

function DetailApplicant () {
    const navigate = useNavigate()
    const [applicantData, setApplicantData] = useState({
        uuid: '',
        identity_url: '',
        selfie_url: '',
        name: '',
        identity_number: '',
        birth_date: '',
        birth_place: ''
    })
    const [menu, setMenu] = useState([])
    const { uuid } = useParams()
    const [loading, setLoading] = useState(true)
    const { pushNotification } = useContext(NotificationContext)

    async function fetchApplicantDetail () {
        setMenu([])
        try {
            setLoading(true)
            const { data: responseData } = await Axios.get(GetApplicantDetail(uuid))
            setApplicantData(responseData.data)
            setMenu([
                {
                    name: 'Verifikasi Pemohon',
                    link: '/applicant-verification'
                },
                {
                    name: `${responseData.data.name}`,
                    link: `/applicant-verification/information/${responseData.data.uuid}`
                }
            ])
        } catch (err) {
            pushNotification('error', null, err)
            navigate('/applicant-verification')
        } finally {
            setLoading(false)
        }
    }

    const viewFile = (url) => {
        window.open(url, '_blank').focus()
    }

    useEffect(() => {
        fetchApplicantDetail()
    }, [])

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <h2>Verifikasi Pemohon</h2>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button variant="link" className="text-decoration-none" as={Link} to="/applicant-verification">Kembali</Button>
                </Col>
            </Row>
            {loading && (
                <div className="mt-3 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            {
                !loading && (
                    <>
                        <Breadcrumbs menu={menu} />
                        <Row className="justify-content-center">
                            <Col md={{ span: 10 }}>
                                <Card>
                                    <Row className="align-items-center mx-0 py-5">
                                        <Col md={{ span: 12 }}>
                                            <Row className="justify-content-center align-items-center">
                                                <Col md={{ span: 12, offset: 1 }}>
                                                    <h3>Informasi Pemohon</h3>
                                                    <Row className='mt-3'>
                                                        <Col>
                                                            <div className='mb-3'>
                                                                <h5>KTP</h5>
                                                                {
                                                                    applicantData.identity_url
                                                                        ? (
                                                                            <p className='link-primary cursor-pointer' onClick={() => viewFile(applicantData.identity_url)}>Lihat File</p>
                                                                        )
                                                                        : '-'
                                                                }
                                                            </div>
                                                            <div className='mb-3'>
                                                                <h5>Nama</h5>
                                                                <p>{applicantData.name}</p>
                                                            </div>
                                                            <div className='mb-3'>
                                                                <h5>No KTP</h5>
                                                                <p>{applicantData.identity_number}</p>
                                                            </div>
                                                            <div className='mb-3'>
                                                                <h5>Tempat Lahir</h5>
                                                                <p>{applicantData.birth_place}</p>
                                                            </div>
                                                            <div className='mb-3'>
                                                                <h5>Tanggal Lahir</h5>
                                                                <p>{dateParserWithFormat(applicantData.birth_date, 'DD-MM-YYYY')}</p>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className='mb-3'>
                                                                <h5>Selfie</h5>
                                                                {
                                                                    applicantData.selfie_url
                                                                        ? (
                                                                            <p className='link-primary cursor-pointer' onClick={() => viewFile(applicantData.selfie_url)}>Lihat File</p>
                                                                        )
                                                                        : '-'
                                                                }
                                                            </div>
                                                            <div className='mb-3'>
                                                                <h5>E-KYC</h5>
                                                                <p>{applicantData.kyc_status}</p>
                                                            </div>
                                                            <div className='mb-3'>
                                                                <h5>Similarity</h5>
                                                                <p>-</p>
                                                            </div>
                                                            <div className='mb-3'>
                                                                <h5>Pendapatan</h5>
                                                                <p>{applicantData.income_range}</p>
                                                            </div>
                                                            <div className='mb-3'>
                                                                <h5>Tempat Bekerja</h5>
                                                                <p>{applicantData.company_name}</p>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )
            }
        </>
    )
}

export default DetailApplicant
