import Axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Form, Button, Alert, Card } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faCheck } from '@fortawesome/free-solid-svg-icons'

import Breadcrumbs from '../../Shared/Breadcrumbs'
import Upload from '../../Shared/Upload'

import { useAnalyticsPushEvent } from '../../../analytics'
import NotificationContext from '../../../contexts/notification'
import { PostCreateApplicant, PostDocUpload } from '../../../utils/api'

function AddAplicant () {
    const navigate = useNavigate()
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()
    const [menu, setMenu] = useState([])
    const [formAddAplicant, setFormApplicant] = useState({
        identity_url: '',
        selfie_url: '',
        name: '',
        identity_number: '',
        birth_date: '',
        birth_place: ''
    })
    const [applicantAddStatusMessage, setApplicantAddStatusMessage] = useState('')
    const [uploading, setUploading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [birthDateError, setBirthDateError] = useState('')

    useEffect(() => {
        setMenu([
            {
                name: 'Verifikasi Pemohon',
                link: '/applicant-verification'
            },
            {
                name: 'Tambah Pemohon',
                link: '#'
            }
        ])

        pushEvent('Add Applicant Form')
    }, [])

    const handleSubmit = async (event) => {
        event.preventDefault()

        setApplicantAddStatusMessage('')
        setIsSubmitting(true)

        let payload = formAddAplicant
        // set selfie to optional if empty
        if (!payload.selfie_url) delete payload.selfie_url

        if (Object.keys(payload).some(key => payload[key] === '') || validateDate(payload.birth_date) !== '') {
            setApplicantAddStatusMessage('Harap isi semua data')
            setIsSubmitting(false)
            return
        }

        const birthDate = formAddAplicant.birth_date.split('/')

        payload = {
            ...formAddAplicant,
            birth_date: `${birthDate[2]}-${birthDate[1]}-${birthDate[0]}`
        }

        try {
            const { data: responseData } = await Axios.post(PostCreateApplicant(), payload)
            pushNotification('success', responseData.message)
            navigate('/applicant-verification')
        } catch (err) {
            const errResData = err?.response?.data
            let text
            if (errResData && errResData.data && errResData.data.validation_error) {
                text = errResData.data.validation_error
            } else if (errResData && errResData.data && errResData.data.message) {
                text = errResData.data.message
            } else if (errResData && errResData.message) {
                text = errResData.message
            } else {
                text = err.message
            };

            setApplicantAddStatusMessage(text)
        } finally {
            setIsSubmitting(false)
        }
    }

    const validateDate = (date) => {
        const arrayDate = date.split('/').map(Number)
        if (arrayDate[0] > 31) {
            return 'Day cannot be greater than 31'
        }
        if (arrayDate[1] > 12) {
            return 'Month cannot be greater than 12'
        }
        return ''
    }

    const handleDateChange = (evnt) => {
        const value = evnt.target.value
        const errorMessage = validateDate(value)
        setBirthDateError(errorMessage)
        setFormApplicant({ ...formAddAplicant, birth_date: value })
    }

    const handleStringChange = (evnt) => {
        const { name, value } = evnt.target
        const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, '')
        setFormApplicant({ ...formAddAplicant, [name]: sanitizedValue })
    }

    const setImageValue = (id, value) => {
        setFormApplicant({
            ...formAddAplicant,
            [id]: value
        })
    }

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <h2>Verifikasi Pemohon</h2>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button variant="link" className="text-decoration-none" as={Link} to="/applicant-verification">Kembali</Button>
                </Col>
            </Row>
            <Breadcrumbs menu={menu} />
            <Row className="justify-content-center">
                <Col md={{ span: 9 }}>
                    <Card>
                        <Row className="align-items-center mx-0 py-5">
                            <Col md={{ span: 12 }}>
                                <Row className="justify-content-center align-items-center">
                                    <Col md={{ span: 10, offset: 1 }}>
                                        <h3>Tambah Pemohon</h3>
                                        {applicantAddStatusMessage && (
                                            <Alert variant="danger" className="mt-3">
                                                <strong className="text-danger"><FontAwesomeIcon icon={faCircleExclamation} size="sm" /></strong> {applicantAddStatusMessage}
                                            </Alert>
                                        )}
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group className="mt-3">
                                                <Form.Label>
                                                    KTP *
                                                    {
                                                        formAddAplicant.identity_url && (
                                                            <span className='ms-1 my-auto text-success'><FontAwesomeIcon icon={faCheck} size="sm" /></span>
                                                        )
                                                    }
                                                </Form.Label>
                                                <Upload
                                                    uploadUrl={PostDocUpload()}
                                                    showLoadingUpload={false}
                                                    setParentFileValue={setImageValue}
                                                    allowImage
                                                    allowZip={false}
                                                    idName={'identity_url'}
                                                    setUploading={setUploading}
                                                    versionComponent={1.1}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mt-3">
                                                <Form.Label>
                                                    Selfie
                                                    {
                                                        formAddAplicant.selfie_url && (
                                                            <span className='ms-1 my-auto text-success'><FontAwesomeIcon icon={faCheck} size="lg" /></span>
                                                        )
                                                    }
                                                </Form.Label>
                                                <Upload
                                                    uploadUrl={PostDocUpload()}
                                                    showLoadingUpload={false}
                                                    setParentFileValue={setImageValue}
                                                    allowImage
                                                    allowZip={false}
                                                    idName={'selfie_url'}
                                                    setUploading={setUploading}
                                                    versionComponent={1.1}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mt-3">
                                                <Form.Label>No KTP *</Form.Label>
                                                <InputMask mask="9999999999999999" maskChar='' value={formAddAplicant.identity_number} onChange={(evnt) => setFormApplicant({ ...formAddAplicant, identity_number: evnt.target.value }) }>
                                                    {(inputProps) => <Form.Control {...inputProps} name="identity-card-number" type="text" placeholder="Masukkan No KTP" autoComplete="off" />}
                                                </InputMask>
                                            </Form.Group>
                                            <Form.Group className="mt-3">
                                                <Form.Label>Nama Lengkap *</Form.Label>
                                                <Form.Control onChange={handleStringChange} value={formAddAplicant.name} name="name" type="text" placeholder="Masukkan Nama Lengkap" autoComplete="off" />
                                            </Form.Group>
                                            <Form.Group className="mt-3">
                                                <Form.Label>Tempat Lahir *</Form.Label>
                                                <Form.Control onChange={handleStringChange} value={formAddAplicant.birth_place} name="birth_place" type="text" placeholder="Masukkan Tempat Lahir" autoComplete="off" />
                                            </Form.Group>
                                            <Form.Group className="mt-3">
                                                <Form.Label>Tanggal Lahir (DD/MM/YYYY) *</Form.Label>
                                                <InputMask
                                                    mask="99/99/9999"
                                                    value={formAddAplicant.birth_date}
                                                    onChange={handleDateChange}
                                                >
                                                    {(inputProps) => (
                                                        <Form.Control
                                                            {...inputProps}
                                                            name="birth_date"
                                                            type="text"
                                                            placeholder="Masukkan Tanggal Lahir (DD/MM/YYYY)"
                                                            autoComplete="off"
                                                            isInvalid={!!birthDateError} // Add this line to trigger invalid styling
                                                        />
                                                    )}
                                                </InputMask>
                                                {birthDateError && <Form.Control.Feedback type="invalid">{birthDateError}</Form.Control.Feedback>}
                                            </Form.Group>
                                            <Row className='justify-content-center'>
                                                <Col className='text-center' md={{ span: 12 }}>
                                                    <Button variant="primary" type="submit" className="mt-4 w-50" disabled={uploading || isSubmitting}>
                                            Tambah
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default AddAplicant
