import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'

import CustomModal from '../Shared/CustomModal'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'
import { DeleteStatement } from '../../utils/api'

function App ({ showModal, setShowModal, selectedItem, onFileDeleted }) {
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()

    // Delete statement.
    const [selected, setSelected] = useState(null)
    const handleAction = () => {
        if (!selected) return

        async function fetchData () {
            try {
                const { data: responseData } = await Axios.delete(DeleteStatement(selected.uuid))
                pushNotification('success', responseData.message)
                setShowModal(false)

                if (onFileDeleted && typeof onFileDeleted === 'function') {
                    onFileDeleted(selected)
                }
            } catch (err) {
                pushNotification('error', null, err)
            }
        }

        fetchData()
    }

    // Inits.
    useEffect(() => {
        if (!selectedItem) return

        setSelected(selectedItem)

        // Adds to amplitude.
        pushEvent('Confirm Delete Statement File', { target: selectedItem })
    }, [selectedItem])

    return (
        <CustomModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            title="Hapus Rekening Koran"
            primaryButtonText="Hapus"
            primaryButtonAction={handleAction}
            primaryButtonVariant="danger"
            secondaryButtonText="Tutup">
            Apakah Anda yakin ingin menghapus Rekening Koran ini?
            <p><strong>{selected?.file_name}</strong></p>
        </CustomModal>
    )
}

export default App
