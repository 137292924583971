import Axios from 'axios'
import React, { useState, useContext } from 'react'
import { Button, Row, Col, Spinner, ProgressBar, Card, CardBody } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'

import CustomInput from '../../Shared/CustomInput'
import CustomModal from '../../Shared/CustomModal'

import AuthContext from '../../../contexts/auth'
import NotificationContext from '../../../contexts/notification'
import { PutUserChangePassword } from '../../../utils/api'

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showPassword, setShowPassword] = useState({
        old: false,
        new: false,
        confirmation: false
    })
    const [openModal, setOpenModal] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { pushNotification } = useContext(NotificationContext)
    const { handleLogout } = useContext(AuthContext)

    const evaluatePasswordStrength = (password) => {
        let strength = 0
        if (password.length >= 8) strength += 1
        if (/[A-Z]/.test(password)) strength += 1
        if (/[a-z]/.test(password)) strength += 1
        if (/\d/.test(password)) strength += 1
        if (/[\W_]/.test(password)) strength += 1
        return strength
    }

    const getPasswordStrengthLabel = (strength) => {
        switch (strength) {
        case 1:
            return 'Sangat Lemah'
        case 2:
            return 'Lemah'
        case 3:
            return 'Sedang'
        case 4:
            return 'Aman'
        case 5:
            return 'Sangat Aman'
        default:
            return ''
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            let errorMessage = ''
            setIsSubmitting(true)
            if (!oldPassword || !newPassword || !confirmPassword) {
                errorMessage = 'Semua data harus diisi'
            } else if (newPassword === oldPassword) {
                errorMessage = 'Password baru tidak boleh sama dengan password lama'
            } else if (newPassword !== confirmPassword) {
                errorMessage = 'Password baru dan Konfirmasi Password baru tidak sama'
            }

            if (errorMessage) {
                pushNotification('error', errorMessage)
                return
            }

            const res = await Axios.put(PutUserChangePassword(), {
                old_password: oldPassword.trim(),
                new_password: newPassword.trim()
            })

            if (res.status === 200) {
                pushNotification('success', res.data?.message)
                setOldPassword('')
                setNewPassword('')
                setConfirmPassword('')
                setOpenModal(true)
            }
        } catch (e) {
            pushNotification('error', 'Gagal ubah password', e)
        } finally {
            setIsSubmitting(false)
        }
    }

    const passwordStrength = evaluatePasswordStrength(newPassword)
    const passwordStrengthLabel = getPasswordStrengthLabel(passwordStrength)

    return (
        <div className="mt-5">
            <Card>
                <CardBody>
                    <Row>
                        <Col>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div>
                                    <label>Password Lama:</label>
                                    <CustomInput
                                        name="old_password"
                                        placeholder="Masukkan Password Lama"
                                        type={showPassword.old ? 'text' : 'password'}
                                        value={oldPassword}
                                        minLength={8}
                                        required={true}
                                        onChange={(e) => setOldPassword(e.target.value)}
                                        icon={<FontAwesomeIcon icon={showPassword.old ? faEyeSlash : faEye} />}
                                        clickableIcon
                                        onIconClick={() =>
                                            setShowPassword({
                                                ...showPassword,
                                                old: !showPassword.old
                                            })
                                        }
                                        inputClassName="mb-4"
                                    />
                                </div>
                                <div>
                                    <label>Password Baru:</label>
                                    <CustomInput
                                        name="new_password"
                                        placeholder="Masukkan Password Baru"
                                        type={showPassword.new ? 'text' : 'password'}
                                        value={newPassword}
                                        minLength={8}
                                        required={true}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        icon={<FontAwesomeIcon icon={showPassword.new ? faEyeSlash : faEye} />}
                                        clickableIcon
                                        onIconClick={() =>
                                            setShowPassword({
                                                ...showPassword,
                                                new: !showPassword.new
                                            })
                                        }
                                        inputClassName="mb-4"
                                    />
                                    <div className="mb-4">
                                        <ProgressBar
                                            now={(passwordStrength / 5) * 100}
                                            variant={passwordStrength >= 4 ? 'success' : passwordStrength >= 3 ? 'warning' : 'danger'}
                                        />
                                        <small>Kekuatan Password: {passwordStrengthLabel}</small>
                                    </div>
                                </div>
                                <div>
                                    <label>Konfirmasi Password Baru:</label>
                                    <CustomInput
                                        name="confirm_new_password"
                                        placeholder="Masukkan Konfirmasi Password Baru"
                                        type={showPassword.confirmation ? 'text' : 'password'}
                                        value={confirmPassword}
                                        minLength={8}
                                        required={true}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        icon={<FontAwesomeIcon icon={showPassword.confirmation ? faEyeSlash : faEye} />}
                                        clickableIcon
                                        onIconClick={() =>
                                            setShowPassword({
                                                ...showPassword,
                                                confirmation: !showPassword.confirmation
                                            })
                                        }
                                        inputClassName="mb-4"
                                    />
                                </div>
                                <Button variant="primary" type="submit" disabled={isSubmitting}>
                                    {isSubmitting && (
                                        <span className="me-2">
                                            <Spinner size="sm" animation="border" variant="white" />
                                        </span>
                                    )}
                                    <span>Ubah Password</span>
                                </Button>
                            </form>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <CustomModal
                show={openModal}
                title={'Sukses Ubah Password'}
                keyboard={false}
                closeButton={false}
                primaryButtonText={'Logout'}
                primaryButtonVariant={'outline-danger'}
                primaryButtonAction={() => handleLogout()}
            >
                Kata sandi Anda telah berhasil diubah. Untuk alasan keamanan, silakan klik tombol di bawah ini untuk keluar. Anda perlu masuk kembali untuk menerapkan perubahan.
            </CustomModal>
        </div>
    )
}

export default ChangePassword
