import React from 'react'
import MonthlyData from './MonthlyData'
import { PostUsageReportStatementMonthlyData } from '../../utils/api'

const App = () => {
    const postDataUrlFn = PostUsageReportStatementMonthlyData
    const pushEventMessage = 'Usage Report Bank Statement Download Click'
    const responseDataTransformer = x => ({
        'Nama Klien': x.company_name,
        'Tanggal Proses': x.processed_at,
        Bank: x.bank_name,
        'Nomor Rekening': x.bank_account_number,
        'Nama Rekening': x.bank_account_name,
        Periode: x.period,
        'Tanggal Hapus': x.deleted_at,
        'Diproses Oleh': x.processed_by
    })
    const excelColsConfigs = [{ wch: 25 }, { wch: 18 }, { wch: 20 }, { wch: 20 }, { wch: 45 }, { wch: 9 }, { wch: 18 }, { wch: 18 }]
    return (
        <MonthlyData postDataUrlFn={postDataUrlFn}
            pushEventMessage={pushEventMessage}
            responseDataTransformer={responseDataTransformer}
            excelColsConfigs={excelColsConfigs}
            prefixName="stmmd" />
    )
}

export default App
