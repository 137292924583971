import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Spinner, Table } from 'react-bootstrap'

import CustomModal from '../Shared/CustomModal'
import TitledFiles from '../Shared/TitledFiles'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'
import { GetMergePefindoDetail, GetPefindoList } from '../../utils/api'

function App ({ showModal, setShowModal, selectedItem }) {
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()
    const [loading, setLoading] = useState(false)

    // Processed files pefindo.
    const [selected, setSelected] = useState(null)
    const [mergePefindo, setMergePefindo] = useState(null)

    const [pefindos, setPefindos] = useState([])
    async function fetchFilesPefindo (item) {
        if (!item) {
            return
        }

        try {
            setLoading(true)

            const { data: detailResponseData } = await Axios.get(GetMergePefindoDetail(item.uuid))
            const ms = detailResponseData?.data
            setMergePefindo(ms)

            const listParams = { merge_uuid: item.uuid }
            const { data: listResponseData } = await Axios.get(GetPefindoList(), { params: listParams })
            setPefindos(listResponseData?.data)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    // Inits.
    useEffect(() => {
        // Resets first.
        setMergePefindo(null)
        setPefindos([])

        if (!selectedItem) return

        fetchFilesPefindo(selectedItem)
        setSelected(selectedItem)

        // Adds to amplitude.
        pushEvent('View Show Result', { clickedItem: selectedItem })
    }, [selectedItem])

    return (
        <CustomModal
            size="xl"
            show={showModal}
            handleClose={() => setShowModal(false)}
            backdrop={true}
            title="Hasil">
            { loading && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            { !loading && <>
                <Table responsive striped bordered hover className="p-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>File</th>
                            <th>Informasi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            pefindos?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <Button size="sm" variant="link" className="text-start text-decoration-none m-0 p-0"
                                            href={item.file_url} target="_blank"
                                        >{item.file_name}</Button>
                                    </td>
                                    <td>{item.pefindo_summary?.borrower_name}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <TitledFiles parent="Pefindo" selected={selected} files={mergePefindo?.processed_files} />
            </>}
        </CustomModal>
    )
}

export default App
