import Axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner as LoadingIcon } from '@fortawesome/free-solid-svg-icons'

import AuthContext from '../contexts/auth'
import NotificationContext from '../contexts/notification'
import { PostAuthUserVerify } from '../utils/api'
import { setCookie, getCookie, removeCookie } from '../utils/cookie'

function PrivateRoute ({ children }) {
    const { pushNotification } = useContext(NotificationContext)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const { setAuthUser } = useContext(AuthContext)

    useEffect(() => {
        async function checkAuthentication () {
            const token = getCookie()
            if (!token) {
                setIsLoading(false)
                return
            }

            try {
                const { data: responseData } = await Axios.post(PostAuthUserVerify())
                if (responseData.data && responseData.data.user) {
                    setCookie({
                        token: responseData.data.token,
                        expiryDate: new Date(responseData.data.expired_at)
                    })
                    localStorage.setItem('partner', JSON.stringify(responseData.data.user))
                    setAuthUser({
                        ...responseData.data.user,
                        twoFactorAuthentication: responseData.data.user.two_factor_authentication,
                        passwordExpired: responseData.data.user.password_expired
                    })
                    setIsAuthenticated(true)
                } else {
                    removeCookie()
                    localStorage.removeItem('partner')
                }
            } catch (err) {
                removeCookie()
                localStorage.removeItem('partner')

                pushNotification('error', null, err)
            } finally {
                setIsLoading(false)
            }
        }
        checkAuthentication()
    }, [])

    if (isLoading) {
        return <FontAwesomeIcon icon={LoadingIcon} className="fa-spin" />
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />
    }

    return children
}

export default PrivateRoute
