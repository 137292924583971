import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Spinner, Table } from 'react-bootstrap'

import MergeStatementShowResultTimerModal from './MergeStatementShowResultTimerModal'
import CustomModal from '../Shared/CustomModal'
import TitledFiles from '../Shared/TitledFiles'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'
import SocketContext from '../../contexts/socket'
import { GetMergeStatementDetail, GetStatementList } from '../../utils/api'

function App ({ showModal, setShowModal, selectedItem }) {
    const { subscribe, unsubscribe, events } = useContext(SocketContext)
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()
    const [loading, setLoading] = useState(false)

    // Processed files statement.
    const [selected, setSelected] = useState(null)
    const [mergeStatement, setMergeStatement] = useState(null)

    const [statements, setStatements] = useState([])
    async function fetchFilesStatement (item) {
        if (!item) {
            return
        }

        try {
            setLoading(true)

            const { data: detailResponseData } = await Axios.get(GetMergeStatementDetail(item.uuid))
            const ms = detailResponseData?.data
            setMergeStatement(ms)

            if (ms?.fine_tuning_status === 'completed') {
                const listParams = { merge_uuid: item.uuid }
                const { data: listResponseData } = await Axios.get(GetStatementList(), { params: listParams })
                setStatements(listResponseData?.data)
            }
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    const onTimerCompleted = () => {
        fetchFilesStatement(selectedItem)
    }

    const notificationDocumentCompletedHandler = (data, si) => {
        if (data?.doc_type !== 'bs') return
        if (data?.doc?.uuid !== si?.uuid) return
        fetchFilesStatement(si)
    }

    // Inits.
    useEffect(() => {
        // Resets first.
        setMergeStatement(null)
        setStatements([])

        if (!selectedItem) return

        fetchFilesStatement(selectedItem)
        setSelected(selectedItem)

        // Adds to amplitude.
        pushEvent('View Show Result', { clickedItem: selectedItem })

        subscribe(events.NOTIFICATION_DOCUMENT_COMPLETED_EVENT, notificationDocumentCompletedHandler, selectedItem)

        return () => {
            unsubscribe(events.NOTIFICATION_DOCUMENT_COMPLETED_EVENT, notificationDocumentCompletedHandler)
        }
    }, [selectedItem])

    return (
        <CustomModal
            size="xl"
            show={showModal}
            handleClose={() => setShowModal(false)}
            backdrop={true}
            title="Hasil">
            { loading && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            { !loading && mergeStatement?.fine_tuning_status === 'onprogress' && (
                <MergeStatementShowResultTimerModal mergeStatement={mergeStatement} onTimerCompleted={onTimerCompleted} />
            )}
            { !loading && mergeStatement?.fine_tuning_status === 'unprocessed' && (
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="p-2">
                        Rekening koran belum dapat diproses. <br/>
                    </div>
                </div>
            )}
            { !loading && mergeStatement?.fine_tuning_status === 'completed' && <>
                <Table responsive striped bordered hover className="p-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>File</th>
                            <th>Informasi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            statements?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <Button size="sm" variant="link" className="text-start text-decoration-none m-0 p-0"
                                            href={item.file_url} target="_blank"
                                        >{item.file_name}</Button>
                                    </td>
                                    <td colSpan="5">
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Bank</th>
                                                    <th>Nama Rekening</th>
                                                    <th>Nomor Rekening</th>
                                                    <th>Periode</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    item.detail_statements?.map((subItem, subIndex) => (
                                                        <tr key={subIndex}>
                                                            <td>{subIndex + 1}</td>
                                                            <td className="text-nowrap">{subItem.bank_name}</td>
                                                            <td>{subItem.bank_account_name}</td>
                                                            <td className="text-nowrap">{subItem.bank_account_number}</td>
                                                            <td className="text-nowrap">{subItem.period}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                <TitledFiles parent="Statement" selected={selected} files={mergeStatement?.processed_files} />
            </>}
        </CustomModal>
    )
}

export default App
