import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Axios from 'axios'
import { GetAutocompletePartnerList } from '../../utils/api'

const PartnerSelections = ({ prefixName, partners, setPartners }) => {
    const [partnerOptions, setPartnerOptions] = useState([])

    const handleSelectChange = (selected) => {
        setPartners(selected || [])
    }

    useEffect(() => {
        const fetchPartnerOptions = async () => {
            try {
                const { data: responseData } = await Axios(GetAutocompletePartnerList())
                const formattedPartners = responseData.data?.map((item) => ({
                    value: item.value,
                    label: item.label
                }))
                setPartnerOptions(formattedPartners || [])
            } catch (_) {
                setPartnerOptions([])
            }
        }

        fetchPartnerOptions()
    }, [])

    return (
        <Select
            isMulti
            options={partnerOptions}
            value={partners}
            onChange={handleSelectChange}
            placeholder="Pilih Pengguna"
        />
    )
}

export default PartnerSelections
