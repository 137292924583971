import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'

import CustomModal from '../Shared/CustomModal'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'
import { DeleteMergeStatement } from '../../utils/api'

function App ({ showModal, setShowModal, selectedItem, onDeleted }) {
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()

    // Delete statement.
    const [selected, setSelected] = useState(null)
    const handleAction = () => {
        if (!selected) return

        async function fetchData () {
            try {
                const { data: responseData } = await Axios.delete(DeleteMergeStatement(selected.uuid))
                pushNotification('success', responseData.message)
                setShowModal(false)

                if (onDeleted && typeof onDeleted === 'function') {
                    onDeleted(selected)
                }
            } catch (err) {
                pushNotification('error', null, err)
            }
        }

        fetchData()
    }

    // Inits.
    useEffect(() => {
        if (!selectedItem) return

        setSelected(selectedItem)

        // Adds to amplitude.
        pushEvent('Confirm Delete Statement Folder', { clickedItem: selectedItem })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])

    return (
        <CustomModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            title="Hapus Rekening Koran"
            primaryButtonText="Hapus"
            primaryButtonAction={handleAction}
            primaryButtonVariant="danger"
            secondaryButtonText="Tutup">
            Apakah Anda yakin ingin menghapus Rekening Koran ini?
            <p><strong>{selected?.name}</strong></p>
        </CustomModal>
    )
}

export default App
