import Axios from 'axios'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useContext } from 'react'
import { Dropdown, ButtonGroup, Row, Col, Button, Card, Spinner } from 'react-bootstrap'
import * as XLSX from 'xlsx'

import PartnerSelections from './PartnerSelections'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'

import './download.css'

const App = ({ postDataUrlFn, pushEventMessage, responseDataTransformer, excelColsConfigs, prefixName }) => {
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()
    const [downloading, setDownloading] = useState(false)

    const now = new Date()
    const currentDate = new Date(now.setMonth(now.getMonth() - 1))
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth()

    const [selectedMonth, setSelectedMonth] = useState(currentMonth)
    const [selectedYear, setSelectedYear] = useState(currentYear)
    const [partners, setPartners] = useState([])

    const years = currentMonth === 11 ? [currentYear, currentYear + 1] : [currentYear - 1, currentYear]
    const months = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei',
        'Juni', 'Juli', 'Agustus', 'September', 'Oktober',
        'November', 'Desember'
    ]

    const handleMonthChange = (index) => {
        setSelectedMonth(index)
    }

    const handleYearChange = (year) => {
        setSelectedYear(year)
    }

    const handleClick = async () => {
        try {
            setDownloading(true)

            const formattedMonth = String(selectedMonth + 1).padStart(2, '0')
            const isoMonth = `${selectedYear}-${formattedMonth}`
            pushEvent(pushEventMessage, {
                month: isoMonth
            })

            // Retrieves data.
            const url = postDataUrlFn()
            const payload = {
                month: isoMonth,
                partners: partners?.map(x => x.value)?.filter(x => x)
            }
            const { data: responseData } = await Axios.post(url, payload)

            // Transforms data.
            const rows = (responseData.data || []).map(responseDataTransformer)

            // Saves as excel.
            const worksheet = XLSX.utils.json_to_sheet(rows)
            worksheet['!cols'] = excelColsConfigs
            const workbook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
            XLSX.writeFile(workbook, `fineksi-${selectedYear}${formattedMonth}-${Math.floor(Date.now() / 1000)}.xlsx`)

            pushNotification('success', 'Unduh Data Berhasil')
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setDownloading(false)
        };
    }

    return (
        <Card className="mb-4 shadow">
            <Card.Header><h5>Data</h5></Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={2}>
                        <strong>Pengguna</strong>
                    </Col>
                    <Col md={10}>
                        <PartnerSelections prefixName={prefixName} partners={partners} setPartners={setPartners} />
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={2}>
                        <strong>Unduh Data Bulanan</strong>
                    </Col>
                    <Col md={6}>
                        <ButtonGroup size="sm">
                            <Dropdown as={ButtonGroup} size="sm" >
                                <Dropdown.Toggle variant="outline-secondary" disabled={downloading}>
                                    {months[selectedMonth]}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {months.map((month, index) => (
                                        <Dropdown.Item key={index} onClick={() => handleMonthChange(index)}>
                                            {month}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown as={ButtonGroup} size="sm">
                                <Dropdown.Toggle variant="outline-secondary" disabled={downloading}>
                                    {selectedYear}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {years.map((year) => (
                                        <Dropdown.Item key={year} onClick={() => handleYearChange(year)}>
                                            {year}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </ButtonGroup>
                        <Button size="sm"
                            onClick={() => handleClick()}
                            disabled={downloading}
                            variant="outline-secondary"
                            className="ms-2 download-button">
                            {!downloading && (<FontAwesomeIcon icon={faSave} size="lg" className="text-primary download-icon" />)}
                            {downloading && (<Spinner size="sm" animation="border" variant="primary" />)}
                            <span className="ps-2">Unduh</span>
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default App
