import Axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { Tab, Tabs, Spinner, Card, Row, Col } from 'react-bootstrap'
import { Bar } from 'react-chartjs-2'

import PartnerSelections from './PartnerSelections'

import NotificationContext from '../../contexts/notification'
import { PostUsageReportPefindoChart as PostChart } from '../../utils/api'
import { chartColors } from '../../utils/constant'

const emptyChartData = {
    labels: [],
    datasets: []
}
const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false
}

const height = 360

const PeriodicChart = ({ type, partners }) => {
    const { pushNotification } = useContext(NotificationContext)
    const [data, setData] = useState(emptyChartData)
    const [loading, setLoading] = useState(false)

    async function fetchDetail (params) {
        try {
            setLoading(true)

            const payload = {
                type: params.type,
                partners: params.partners?.map(x => x.value)?.filter(x => x)
            }
            const { data: responseData } = await Axios.post(PostChart(), payload)
            const labels = responseData.data.map((x) => x.period_label)
            const counts = responseData.data.map((x) => x.count)
            setData({
                labels,
                datasets: [
                    {
                        label: 'Jumlah',
                        data: counts,
                        backgroundColor: chartColors.blue
                    }
                ]
            })
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchDetail({ type, partners })
    }, [type, partners])

    if (loading) {
        return (
            <div style={{ height: `${height}px` }} className="mt-3 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />
            </div>
        )
    }

    return (
        <div style={{ height: `${height}px` }}>
            <Bar data={data} options={options} />
        </div>
    )
}

const App = () => {
    const [key, setKey] = useState('tab-daily')
    const [partners, setPartners] = useState([])
    return (
        <Card className="mb-4 shadow">
            <Card.Header><h5>Grafik</h5></Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md={2}>
                        <strong>Pengguna</strong>
                    </Col>
                    <Col md={10}>
                        <PartnerSelections prefixName="pfdch" partners={partners} setPartners={setPartners} />
                    </Col>
                </Row>
                <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="my-3" fill mountOnEnter>
                    <Tab eventKey="tab-daily" title={<h4>Harian</h4>}>
                        <PeriodicChart type="daily" partners={partners} />
                    </Tab>
                    <Tab eventKey="tab-monthly" title={<h4>Bulanan</h4>}>
                        <PeriodicChart type="monthly" partners={partners} />
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    )
}

export default App
