import React from 'react'

const NotificationContext = React.createContext()

const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = React.useState([])

    const pushNotification = (type, message, err) => {
        let text
        if (err) {
            const errResData = err?.response?.data
            if (errResData && errResData.data && errResData.data.validation_error) {
                text = errResData.data.validation_error
            } else if (errResData && errResData.data && errResData.data.message) {
                text = errResData.data.message
            } else if (errResData && errResData.message) {
                text = `${errResData.message}${errResData.error_code ? ` (${errResData.error_code})` : ''}`
            } else {
                text = err.message
            };
        } else {
            text = message
        }

        setNotifications((notifications) => [
            ...notifications,
            {
                id: new Date().getTime(),
                type,
                text
            }]
        )
    }

    const value = {
        notifications,
        setNotifications,
        pushNotification
    }

    return (
        <NotificationContext.Provider value={value}>
            {children}
        </NotificationContext.Provider>
    )
}

export { NotificationProvider }
export default NotificationContext
