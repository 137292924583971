import Cookies from 'js-cookie'

const authCookieName = 'token'

export const getCookie = () => Cookies.get(authCookieName) || ''

export const setCookie = ({ token, expiryDate }) => {
    Cookies.set(authCookieName, token, {
        expires: new Date(expiryDate),
        secure: process.env.REACT_APP_MODE_ENV !== 'local',
        sameSite: 'Strict'
    })
}

export const removeCookie = () => {
    Cookies.remove(authCookieName)
}
