import React, { useEffect } from 'react'
import { useAnalyticsPushEvent } from '../../analytics'
import TrailMonthlyData from './TrailMonthlyData'

function App () {
    const pushEvent = useAnalyticsPushEvent()

    // Inits.
    useEffect(() => {
        pushEvent('List SLIK')
    }, [pushEvent])

    return (
        <>
            <h2>Audit Trail</h2>
            <TrailMonthlyData />
        </>
    )
}

export default App
