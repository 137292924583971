import React from 'react'
import { Container } from 'react-bootstrap'

import AppConfig from '../../app.config.json'

import './index.css'

const Footer = () => {
    return (
        <div className="footer py-2 fixed-bottom">
            <Container className="text-center">
                <p className="my-0">© {new Date().getFullYear()} {AppConfig.name}</p>
            </Container>
        </div>
    )
}

export default Footer
