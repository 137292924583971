import Axios from 'axios'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js'
import React, { useState, useEffect, useContext } from 'react'
import { Nav, Spinner, Tab } from 'react-bootstrap'

import PefindoChart from './PefindoChart'
import PefindoMonthlyData from './PefindoMonthlyData'
import SlikChart from './SlikChart'
import SlikMonthlyData from './SlikMonthlyData'
import StatementChart from './StatementChart'
import StatementMonthlyData from './StatementMonthlyData'
import VerificationIncomeChart from './VerificationIncomeChart'

import { useAnalyticsPushEvent } from '../../analytics'
import NotificationContext from '../../contexts/notification'
import { GetUsageReportSection } from '../../utils/api'

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend)

const SECTION_MAPPERS = {
    statement: [{
        title: 'Rekening Koran',
        chart: <>
            <StatementMonthlyData />
            <StatementChart title="Rekening Koran" />
        </>
    }],
    slik: [{
        title: 'Sistem Layanan Informasi Keuangan (SLIK)',
        chart: <>
            <SlikMonthlyData />
            <SlikChart title="Sistem Layanan Informasi Keuangan(SLIK)" />
        </>
    }],
    pefindo: [{
        title: 'PEFINDO',
        chart: <>
            <PefindoMonthlyData />
            <PefindoChart title="PEFINDO" />
        </>
    }],
    verification: [{
        title: 'Verifikasi Pendapatan Pemohon',
        chart: <VerificationIncomeChart title="Verifikasi Pendapatan Pemohon" />
    }]
}

const UsageTabs = ({ sections }) => {
    const sectionItems = sections?.map((s) => SECTION_MAPPERS[s])?.flat()?.filter(x => x?.title)
    if (!sectionItems?.length) return <>{sections}</>

    const [key, setKey] = useState('section_0')
    return (
        <Tab.Container activeKey={key} onSelect={(k) => setKey(k)} fill mountOnEnter>
            <Nav variant="underline">
                {
                    sectionItems.map((section, sectionIndex) => ((
                        <Nav.Item key={`section_${sectionIndex}`}>
                            <Nav.Link eventKey={`section_${sectionIndex}`} className="text-black">{section.title}</Nav.Link>
                        </Nav.Item>
                    )))
                }
            </Nav>
            <Tab.Content>
                {
                    sectionItems.map((section, sectionIndex) => ((
                        <Tab.Pane key={`section_${sectionIndex}`} eventKey={`section_${sectionIndex}`}>
                            <h3 className="my-3">Laporan Penggunaan {section.title}</h3>
                            {section.chart}
                        </Tab.Pane>
                    )))
                }
            </Tab.Content>
        </Tab.Container>
    )
}

function App () {
    const { pushNotification } = useContext(NotificationContext)
    const pushEvent = useAnalyticsPushEvent()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    async function fetchDetail (type) {
        try {
            setLoading(true)

            const params = { type }
            const { data: responseData } = await Axios.get(GetUsageReportSection(), { params })
            setData(responseData.data)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    // Inits.
    useEffect(() => {
        pushEvent('Usage Report Dashboard')
        fetchDetail()
    }, [pushEvent])

    return (
        <>
            {
                loading
                    ? (
                        <div className="mt-3 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )
                    : <UsageTabs sections={data} />
            }
        </>
    )
}

export default App
